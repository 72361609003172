// src/Home.js
import React, {useState} from 'react';
import {signInWithPopup} from "firebase/auth";
import {auth, provider} from "../services/googleConfig";
import {Modal, Button, Form} from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function HomePage() {
    const [value, setValue] = useState('');
    const [showJobDescriptionModal, setShowJobDescriptionModal] = useState(false);
    const [showUploadCvModal, setShowUploadCvModal] = useState(false);
    const [jobDescription, setJobDescription] = useState('');
    const [cvFile, setCvFile] = useState(null);
    const [formData, setFormData] = useState({
        job_description: "",
        cv_file: null
    });

    const handleClick = () => {
        signInWithPopup(auth, provider).then((data) => {
            setValue(data.user.email);
            localStorage.setItem("email", data.user.email);
        }).catch((e) => {
            console.log(e)
        });
    };

    const handleJobDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleCvFileChange = (e) => {
        setCvFile(e.target.files[0]);
    };

    const handleRemoveFile = () => {
        setCvFile(null); // Reset cvFile to null to remove the selected file
        setFormData({
            job_description: jobDescription,
            cv_file: null
        })
    };

    const openJobDescModel = () => {
        setShowJobDescriptionModal(true);
        setJobDescription(formData.job_description || '')
    }

    const openCVModel = () => {
        setShowUploadCvModal(true);
        setCvFile(formData.cv_file || null)
    }

    const handleJobDescriptionSubmit = () => {
        // Update jobDescription state only on submit
        console.log("Job Description submitted:", jobDescription);
        setFormData({
            job_description: jobDescription,
            cv_file: cvFile
        })
        setShowJobDescriptionModal(false);
    };

    const handleCvFileSubmit = () => {
        // Update cvFile state only on submit
        setFormData({
            job_description: jobDescription,
            cv_file: cvFile
        })
        setShowUploadCvModal(false);
    };

    const handleCustomButtonClick = () => {
        // Trigger the file input programmatically
        const fileInput = document.getElementById('fileInput');
        if (fileInput) fileInput.click();
    };


    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-end mb-4">
                {!value ? (
                    <button className="g-button" onClick={handleClick}>
                        Sign in with Google
                    </button>
                ) : (
                    <div className="g-button">Welcome, {value}</div>
                )}
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="p-3">
                        <h2>Evaluate a CV of a candidate with AI</h2>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="p-3">
                        <div>
                            {(formData.job_description) ? <button className={`main-btn mb-5 w-100`}
                                                                                    onClick={() => openJobDescModel()}>
                                Job description pasted!
                            </button> : <button className={`disable-btn mb-5 w-100`}
                                                onClick={() => openJobDescModel()}>
                                Paste your job description
                            </button>}

                        </div>
                        <div>
                            {formData.cv_file ?
                                <button className="main-btn mb-5 w-100" onClick={() => openCVModel()}>
                                    CV Uploaded!
                                </button> :
                                <button className="disable-btn mb-5 w-100" onClick={() => openCVModel()}>
                                    Upload your CV
                                </button>}
                        </div>
                        <div>
                            <button className="analyze-btn w-100">
                                Analyze <span>(10 tokens)</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Job Description Modal */}
            <Modal className="custom-modal" show={showJobDescriptionModal}
                   onHide={() => setShowJobDescriptionModal(false)} size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   backdrop="static" // Prevents closing on outside click
                   keyboard={false} // Prevents closing on ESC key press
                   centered>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                rows={13}
                                value={jobDescription}
                                onChange={handleJobDescriptionChange}
                                placeholder='Paste your job description...'
                                className='job-description-textarea'
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleJobDescriptionSubmit()}>
                        Confirm
                    </Button>
                    <Button variant="secondary" onClick={() => setShowJobDescriptionModal(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Upload CV Modal */}
            <Modal className="custom-modal" show={showUploadCvModal} onHide={() => setShowUploadCvModal(false)}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   backdrop="static" // Prevents closing on outside click
                   keyboard={false} // Prevents closing on ESC key press
                   centered>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <Form>
                        <Form.Group>
                            <Form.Control
                                id="fileInput" // Add an ID for easy reference
                                type="file"
                                accept=".pdf"
                                onChange={handleCvFileChange}
                                style={{ display: 'none' }} // Hide the default file input
                            />
                            <Button className='file-btn' onClick={handleCustomButtonClick}>
                                {cvFile ? cvFile.name :'Open Files'}
                            </Button>
                            {formData.cv_file ? <FontAwesomeIcon className='ml-2 icon-container' onClick={handleRemoveFile} icon={faTimes}/> : ''}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"  onClick={() => handleCvFileSubmit()}>
                        OK
                    </Button>
                    <Button variant="secondary" onClick={() => setShowUploadCvModal(false)}>
                        Cancel
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default HomePage;
