import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB3_Se_e1Fn2WCqoCtP8j8Yr9buivAHXQk",
    authDomain: "jd-pasted.firebaseapp.com",
    projectId: "jd-pasted",
    storageBucket: "jd-pasted.appspot.com",
    messagingSenderId: "866301027285",
    appId: "1:866301027285:web:c2e22b4e66912bd0d0935c",
    measurementId: "G-9P3VW506S3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {auth,provider};
